import React from 'react';
import { Check, Home, Calendar, Shield, Globe } from 'lucide-react';
import { Link, useLocation, Navigate } from 'react-router-dom';

const translations = {
  ro: {
    title: "Vă mulțumim pentru comandă!",
    orderNumber: "Numărul poliței Carte Verde este:",
    emailInfo: "În curând veți primi un email cu polița și detaliile comenzii.",
    backHome: "Înapoi la pagina principală",
    paymentPlan: {
      title: "Plan de plată",
      monthlyPayment: "Plata lunară",
      totalPayment: "Suma totală",
      numberOfPayments: "Număr de rate",
      discount: "Economie",
      months: {
        one: "lună",
        other: "luni"
      }
    },
    details: {
      title: "Detalii poliță Carte Verde",
      vehicle: "Automobil",
      plate: "Număr înmatriculare",
      towing: {
        title: "Remorcă",
        plate: "Număr înmatriculare remorcă"
      },
      period: "Perioada asigurării",
      zone: "Zona de deplasare",
      price: {
        mdl: "Suma totală (MDL)",
        eur: "Suma totală (EUR)"
      }
    },
    zones: {
      1: "Ucraina",
      3: "Toate țările sistemului Carte Verde (UE)"
    }
  },
  ru: {
    title: "Спасибо за ваш заказ!",
    orderNumber: "Номер полиса Зеленая Карта:",
    emailInfo: "Вскоре вы получите электронное письмо с полисом и деталями заказа.",
    backHome: "Вернуться на главную",
    paymentPlan: {
      title: "План оплаты",
      monthlyPayment: "Ежемесячный платёж",
      totalPayment: "Общая сумма",
      numberOfPayments: "Количество платежей",
      discount: "Экономия",
      months: {
        one: "месяц",
        few: "месяца",
        many: "месяцев"
      }
    },
    details: {
      title: "Детали полиса Зеленая Карта",
      vehicle: "Автомобиль",
      plate: "Номерной знак",
      towing: {
        title: "Прицеп",
        plate: "Номерной знак прицепа"
      },
      period: "Период страхования",
      zone: "Зона передвижения",
      price: {
        mdl: "Итоговая сумма (MDL)",
        eur: "Итоговая сумма (EUR)"
      }
    },
    zones: {
      1: "Украина",
      3: "Все страны системы Зеленая Карта (ЕС)"
    }
  }
};

const formatNumber = (number) => {
  if (number === null || number === undefined) return "0.00";
  return Number(number).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const OrderSuccessRCAE = ({ lang = 'ro' }) => {
  const location = useLocation();
  const orderData = location.state?.orderData;

  if (!orderData) {
    return <Navigate to="/" />;
  }

  const t = translations[lang];

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat(lang === 'ro' ? 'ro' : 'ru', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(new Date(dateString));
  };

  const getPaymentDetails = () => {
    const rawPriceMDL = parseFloat(orderData.priceMDL.replace(/,/g, ''));
    const rawExchangeRate = parseFloat(orderData.exchangeRate.replace(/,/g, ''));

    switch(orderData.selectedPaymentOption) {
      case 'oneMonth':
        return {
          months: 1,
          totalMDL: formatNumber(rawPriceMDL * 0.85),
          totalEUR: formatNumber((rawPriceMDL * 0.85) / rawExchangeRate),
          monthlyMDL: formatNumber(rawPriceMDL * 0.85),
          monthlyEUR: formatNumber((rawPriceMDL * 0.85) / rawExchangeRate),
          discount: 15,
          savings: formatNumber(rawPriceMDL * 0.15)
        };
      case 'twoMonths':
        return {
          months: 2,
          totalMDL: formatNumber(rawPriceMDL * 0.925),
          totalEUR: formatNumber((rawPriceMDL * 0.925) / rawExchangeRate),
          monthlyMDL: formatNumber((rawPriceMDL * 0.925) / 2),
          monthlyEUR: formatNumber(((rawPriceMDL * 0.925) / rawExchangeRate) / 2),
          discount: 7.5,
          savings: formatNumber(rawPriceMDL * 0.075)
        };
      case 'threeMonths':
      default:
        return {
          months: 3,
          totalMDL: formatNumber(rawPriceMDL),
          totalEUR: formatNumber(rawPriceMDL / rawExchangeRate),
          monthlyMDL: formatNumber(rawPriceMDL / 3),
          monthlyEUR: formatNumber((rawPriceMDL / rawExchangeRate) / 3),
          discount: 0,
          savings: formatNumber(0)
        };
    }
  };

  const paymentDetails = getPaymentDetails();

  const getMonthsText = (number) => {
    if (lang === 'ro') {
      return number === 1 ? t.paymentPlan.months.one : t.paymentPlan.months.other;
    } else {
      if (number === 1) return t.paymentPlan.months.one;
      if (number >= 2 && number <= 4) return t.paymentPlan.months.few;
      return t.paymentPlan.months.many;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-2 py-4 sm:px-4">
      <div className="w-full max-w-lg bg-white rounded-xl sm:rounded-2xl shadow-sm p-4 sm:p-6 md:p-8">
        {/* Success Header */}
        <div className="text-center mb-6 sm:mb-8">
          <div className="mx-auto w-12 h-12 sm:w-16 sm:h-16 bg-green-100 rounded-full flex items-center justify-center mb-3 sm:mb-4">
            <Check className="w-6 h-6 sm:w-8 sm:h-8 text-green-600" />
          </div>

          <h1 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
            {t.title}
          </h1>

          <div className="text-xs sm:text-sm text-gray-600 mb-2">
            {t.orderNumber}
          </div>

          <div className="bg-gray-50 rounded-lg p-2 sm:p-3 mb-2">
            <code className="text-xs sm:text-sm font-mono text-green-600 break-all">
              {orderData.documentNumber}
            </code>
          </div>

          <p className="text-xs sm:text-sm text-gray-600">
            {t.emailInfo}
          </p>
        </div>

        {/* Payment Plan Details */}
        <div className="bg-green-50 rounded-lg sm:rounded-xl p-3 sm:p-4 mb-4 sm:mb-6">
          <h3 className="text-sm font-semibold text-gray-900 mb-3">{t.paymentPlan.title}</h3>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">{t.paymentPlan.monthlyPayment}</span>
              <div className="text-right">
                <div className="text-sm font-bold text-green-600">{paymentDetails.monthlyMDL} MDL</div>
                <div className="text-xs text-gray-600">{paymentDetails.monthlyEUR} EUR</div>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">{t.paymentPlan.numberOfPayments}</span>
              <span className="text-sm font-medium">
                {paymentDetails.months} {getMonthsText(paymentDetails.months)}
              </span>
            </div>

            {paymentDetails.discount > 0 && (
              <div className="flex justify-between items-center">
                <span className="text-sm text-gray-600">{t.paymentPlan.discount}</span>
                <div className="text-right">
                  <div className="text-sm font-medium text-green-600">
                    {paymentDetails.savings} MDL ({paymentDetails.discount}%)
                  </div>
                </div>
              </div>
            )}

            <div className="pt-2 border-t flex justify-between items-center">
              <span className="text-sm font-medium">{t.paymentPlan.totalPayment}</span>
              <div className="text-right">
                <div className="text-lg font-bold text-green-600">{paymentDetails.totalMDL} MDL</div>
                <div className="text-sm text-gray-600">{paymentDetails.totalEUR} EUR</div>
              </div>
            </div>
          </div>
        </div>

        {/* Order Details */}
        <div className="bg-gray-50 rounded-lg sm:rounded-xl p-3 sm:p-4 mb-4 sm:mb-6">
          <div className="flex items-center gap-2 mb-3 sm:mb-4 pb-2 border-b border-gray-200">
            <Globe className="w-4 h-4 sm:w-5 sm:h-5 text-green-600" />
            <h2 className="text-base sm:text-lg font-semibold text-gray-900">
              {t.details.title}
            </h2>
          </div>

          <div className="space-y-2 sm:space-y-3">
            <div className="flex justify-between items-center">
              <span className="text-xs sm:text-sm text-gray-600">{t.details.vehicle}</span>
              <span className="text-xs sm:text-sm font-medium">{orderData.carModel}</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-xs sm:text-sm text-gray-600">{t.details.plate}</span>
              <span className="text-xs sm:text-sm font-medium">{orderData.plateNumber}</span>
            </div>

            {orderData.towingModel && (
              <>
                <div className="flex justify-between items-center">
                  <span className="text-xs sm:text-sm text-gray-600">{t.details.towing.title}</span>
                  <span className="text-xs sm:text-sm font-medium">{orderData.towingModel}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-xs sm:text-sm text-gray-600">{t.details.towing.plate}</span>
                  <span className="text-xs sm:text-sm font-medium">{orderData.towingPlateNumber}</span>
                </div>
              </>
            )}

            <div className="flex justify-between items-center">
              <div className="flex items-center gap-1.5 sm:gap-2 text-gray-600">
                <Calendar className="w-3 h-3 sm:w-4 sm:h-4" />
                <span className="text-xs sm:text-sm">{t.details.period}</span>
              </div>
              <span className="text-xs sm:text-sm font-medium">
                {formatDate(orderData.startDate)} - {formatDate(orderData.expirationDate)}
              </span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-xs sm:text-sm text-gray-600">{t.details.zone}</span>
              <span className="text-xs sm:text-sm font-medium">{t.zones[orderData.zone]}</span>
            </div>
          </div>
        </div>

        {/* Back Button */}
        <Link 
          to="/"
          className="w-full inline-flex items-center justify-center gap-1.5 sm:gap-2 bg-green-600 hover:bg-green-700 text-white rounded-lg sm:rounded-xl px-4 sm:px-6 py-2.5 sm:py-3 text-xs sm:text-sm font-medium transition-colors"
        >
          <Home className="w-3 h-3 sm:w-4 sm:h-4" />
          <span>{t.backHome}</span>
        </Link>
      </div>
    </div>
  );
};

export default OrderSuccessRCAE;