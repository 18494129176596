import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, Calendar, Clock, Mail, Phone, ArrowRight, ArrowLeft, Globe } from 'lucide-react';

const formatNumber = (number) => {
  if (number === null || number === undefined) return "0.00";
  return Number(number).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const translations = {
  ro: {
    title: "Detalii Asigurare Carte Verde",
    back: "Înapoi",
    details: "Detalii poliță",
    vehicle: "Automobil",
    plateNumber: "Număr înmatriculare",
    towing: {
      title: "Remorcă",
      plateNumber: "Număr înmatriculare remorcă"
    },
    company: "Denumirea companiei",
    idnp: "IDNP",
    idno: "IDNO",
    validityTerm: "Termenul de valabilitate",
    period: "Perioada asigurării",
    zone: "Zona de deplasare",
    zones: {
      1: "Ucraina",
      3: "Toate țările sistemului Carte Verde (UE)"
    },
    days: "zile",
    validityPeriods: {
      15: '15 zile',
      30: '1 lună',
      60: '2 luni',
      90: '3 luni',
      120: '4 luni',
      150: '5 luni',
      180: '6 luni',
      210: '7 luni',
      240: '8 luni',
      270: '9 luni',
      300: '10 luni',
      330: '11 luni',
      365: '12 luni'
    },
    price: {
      mdl: "Preț în MDL",
      eur: "Preț în EUR",
      rate: "Curs valutar"
    },
    benefits: {
      support: "Suport 24/7",
      coverage: "Acoperire în toate țările selectate",
      processing: "Procesare rapidă"
    },
    payment: {
      contacts: {
        title: "Date de contact",
        phone: "Număr de telefon",
        email: "Adresa de email"
      },
      orderButton: "Comandă asigurarea",
      processing: "Se procesează...",
      errors: {
        orderFailed: "Eroare la procesarea comenzii. Vă rugăm să încercați din nou."
      },
      options: {
        oneMonth: "1 lună",
        twoMonths: "2 luni",
        threeMonths: "3 luni"
      },
      perMonth: "lună",
      savings: "Economisiți"
    }
  },
  ru: {
    title: "Детали Страховки Зеленая Карта",
    back: "Назад",
    details: "Детали полиса",
    vehicle: "Автомобиль",
    plateNumber: "Номерной знак",
    towing: {
      title: "Прицеп",
      plateNumber: "Номерной знак прицепа"
    },
    company: "Название компании",
    idnp: "IDNP",
    idno: "IDNO",
    validityTerm: "Срок действия",
    period: "Период страхования",
    zone: "Зона передвижения",
    zones: {
      1: "Украина",
      3: "Все страны системы Зеленая Карта (ЕС)"
    },
    days: "дней",
    validityPeriods: {
      15: '15 дней',
      30: '1 месяц',
      60: '2 месяца',
      90: '3 месяца',
      120: '4 месяца',
      150: '5 месяцев',
      180: '6 месяцев',
      210: '7 месяцев',
      240: '8 месяцев',
      270: '9 месяцев',
      300: '10 месяцев',
      330: '11 месяцев',
      365: '12 месяцев'
    },
    price: {
      mdl: "Цена в MDL",
      eur: "Цена в EUR",
      rate: "Курс валюты"
    },
    benefits: {
      support: "Поддержка 24/7",
      coverage: "Покрытие во всех выбранных странах",
      processing: "Быстрая обработка"
    },
    payment: {
      contacts: {
        title: "Контактные данные",
        phone: "Номер телефона",
        email: "Электронная почта"
      },
      orderButton: "Заказать страховку",
      processing: "Обработка...",
      errors: {
        orderFailed: "Ошибка при обработке заказа. Пожалуйста, попробуйте еще раз."
      },
      options: {
        oneMonth: "1 месяц",
        twoMonths: "2 месяца",
        threeMonths: "3 месяца"
      },
      perMonth: "месяц",
      savings: "Экономия"
    }
  }
};

const InsurancePreviewRCAE = ({ data, lang, onBack }) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [contact, setContact] = useState({ phone: '', email: '' });
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const contactFormRef = useRef(null);
  const t = translations[lang];

  useEffect(() => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.email);
    const isValidPhone = contact.phone.length >= 8;
    setIsFormValid(isValidEmail && isValidPhone);
  }, [contact]);

  useEffect(() => {
    if (selectedOption && contactFormRef.current) {
      const yOffset = -20;
      const element = contactFormRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });

      const firstInput = element.querySelector('input');
      if (firstInput) {
        setTimeout(() => {
          firstInput.focus();
        }, 500);
      }
    }
  }, [selectedOption]);

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat(lang === 'ro' ? 'ro' : 'ru', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(new Date(dateString));
  };

  const calculateEndDate = (startDate, validity) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + validity - 1);
    return date;
  };

  // Dann im calculatePaymentOptions:
  const calculatePaymentOptions = (basePriceMDL, basePriceEUR, exchangeRate) => {
  
    const rawPriceMDL = Number(basePriceMDL.replace(/,/g, ''));
    const rawPriceEUR = Number(basePriceEUR.replace(/,/g, '')); // 👈 API-Preis in EUR
    const rawExchangeRate = Number(exchangeRate.replace(/,/g, ''));

    return {
      oneMonth: {
        title: t.payment.options.oneMonth,
        priceMDL: formatNumber(rawPriceMDL * 0.85),
        priceEUR: formatNumber(rawPriceEUR * 0.85),
        discount: 15,
        monthlyCostMDL: formatNumber(rawPriceMDL * 0.85),
        monthlyCostEUR: formatNumber((rawPriceMDL * 0.85) / rawExchangeRate),
        savings: formatNumber(rawPriceMDL * 0.15)
      },
      twoMonths: {
        title: t.payment.options.twoMonths,
        priceMDL: formatNumber(rawPriceMDL * 0.925),
        priceEUR: formatNumber(rawPriceEUR * 0.925),
        discount: 7.5,
        monthlyCostMDL: formatNumber((rawPriceMDL * 0.925) / 2),
        monthlyCostEUR: formatNumber(((rawPriceMDL * 0.925) / rawExchangeRate) / 2),
        savings: formatNumber(rawPriceMDL * 0.075)
      },
      threeMonths: {
        title: t.payment.options.threeMonths,
        priceMDL: basePriceMDL,
        priceEUR: basePriceEUR,
        discount: 0,
        monthlyCostMDL: formatNumber(rawPriceMDL / 3),
        monthlyCostEUR: formatNumber(rawPriceEUR / 3),
        savings: formatNumber(0)
      }
    };
  };

  const handleSubmitOrder = async () => {
    if (!isFormValid || isSubmitting) return;

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      const endDate = calculateEndDate(data.startDate, data.insuranceValidity);
      const selectedPayment = calculatePaymentOptions(data.priceMDL, data.priceEUR, data.exchangeRate)[selectedOption];

      const orderPayload = {
        customerEmail: contact.email,
        phone: contact.phone,
        orderDetails: {
          ...data,
          expirationDate: endDate.toISOString(),
          finalPriceMDL: selectedPayment.priceMDL,
          finalPriceEUR: selectedPayment.priceEUR,
          selectedPaymentOption: selectedOption
        },
        lang
      };

      const response = await fetch('/api/orders/rcae', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderPayload)
      });

      if (!response.ok) {
        throw new Error('Order failed');
      }

      navigate('/order-success-rcae', {
        state: {
          orderData: {
            ...data,
            selectedPaymentOption: selectedOption,
            expirationDate: endDate.toISOString(),
            finalPriceMDL: selectedPayment.priceMDL,
            finalPriceEUR: selectedPayment.priceEUR,
            contact: {
              email: contact.email,
              phone: contact.phone
            }
          },
          lang
        }
      });

    } catch (error) {
      console.error('Order error:', error);
      setSubmitError(t.payment.errors.orderFailed);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isCompany = data?.idnp?.startsWith('1');

  return (
    <div className="w-full bg-white rounded-xl p-4 shadow-sm">
      <button
        onClick={onBack}
        className="flex items-center gap-1.5 text-gray-600 hover:text-green-600 mb-4 group"
      >
        <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform" />
        <span className="text-sm">{t.back}</span>
      </button>

      <div className="flex items-center gap-2 mb-4 pb-3 border-b">
        <Globe className="w-5 h-5 text-green-600" />
        <h2 className="text-lg font-bold text-gray-900">{t.title}</h2>
      </div>

      <div className="bg-gray-50 rounded-lg p-4 mb-4">
        <div className="space-y-3">
          <div className="flex justify-between items-center pb-2 border-b">
            <span className="text-sm font-medium text-gray-900">{t.details}</span>
            <span className="text-sm font-bold text-green-600">{data?.documentNumber}</span>
          </div>

          <div className="grid gap-2">
            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{t.vehicle}</span>
              <span className="text-right font-medium text-sm">{data?.carModel || "N/A"}</span>
            </div>
            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{t.plateNumber}</span>
              <span className="text-right font-medium text-sm">{data?.plateNumber || "N/A"}</span>
            </div>

            {data?.towingModel && (
              <>
                <div className="grid grid-cols-2 items-center">
                  <span className="text-gray-600 text-sm">{t.towing.title}</span>
                  <span className="text-right font-medium text-sm">{data.towingModel}</span>
                </div>
                <div className="grid grid-cols-2 items-center">
                  <span className="text-gray-600 text-sm">{t.towing.plateNumber}</span>
                  <span className="text-right font-medium text-sm">{data.towingPlateNumber}</span>
                </div>
              </>
            )}

            {isCompany && data?.contractorName && (
              <div className="grid grid-cols-2 items-center">
                <span className="text-gray-600 text-sm">{t.company}</span>
                <span className="text-right font-medium text-sm">{data.contractorName}</span>
              </div>
            )}

            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{isCompany ? t.idno : t.idnp}</span>
              <span className="text-right font-medium text-sm">{data?.idnp}</span>
            </div>

            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{t.validityTerm}</span>
              <span className="text-right font-medium text-sm">
                {t.validityPeriods[data?.insuranceValidity] || `${data?.insuranceValidity || 0} ${t.days}`}
              </span>
            </div>

            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{t.period}</span>
              <span className="text-right font-medium text-sm">
                {formatDate(data?.startDate)} - {formatDate(calculateEndDate(data?.startDate, data?.insuranceValidity))}
              </span>
            </div>

            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{t.zone}</span>
              <span className="text-right font-medium text-sm">{t.zones[data?.zone]}</span>
            </div>

            <div className="grid grid-cols-2 items-center pt-2 mt-2 border-t">
              <span className="text-gray-600 text-sm">{t.price.mdl}</span>
              <span className="text-right font-bold text-green-600">{data?.priceMDL?.toLocaleString()} MDL</span>
            </div>
            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{t.price.eur}</span>
              <span className="text-right font-medium text-sm">{data?.priceEUR?.toLocaleString()} EUR</span>
                          </div>
                          <div className="grid grid-cols-2 items-center">
                            <span className="text-gray-600 text-sm">{t.price.rate}</span>
                            <span className="text-right font-medium text-sm">{data?.exchangeRate}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between mb-4">
                      <div className="flex items-center gap-1.5">
                        <Clock className="w-4 h-4 text-green-600" />
                        <span className="text-xs text-gray-600">{t.benefits.support}</span>
                      </div>
                      <div className="flex items-center gap-1.5">
                        <Shield className="w-4 h-4 text-green-600" />
                        <span className="text-xs text-gray-600">{t.benefits.coverage}</span>
                      </div>
                      <div className="flex items-center gap-1.5">
                        <Calendar className="w-4 h-4 text-green-600" />
                        <span className="text-xs text-gray-600">{t.benefits.processing}</span>
                      </div>
                    </div>

                    {submitError && (
                      <div className="mb-4 p-3 bg-red-50 text-red-600 text-sm rounded-lg">
                        {submitError}
                      </div>
                    )}

                    <div className="bg-blue-50 rounded-lg p-4 mb-4">
                      <div className="text-center mb-3">
                        <span className="text-sm font-medium text-gray-700">{t.price.mdl}: </span>
                        <span className="text-xl font-bold text-gray-900">{data?.priceMDL?.toLocaleString()} MDL</span>
                      </div>
                      <div className="text-center mb-3">
                        <span className="text-sm font-medium text-gray-700">{t.price.eur}: </span>
                        <span className="text-xl font-bold text-gray-900">{data?.priceEUR?.toLocaleString()} EUR</span>
                      </div>
                      <div className="text-center mb-4">
                        <span className="text-sm font-medium">{t.payment.selectPeriod}</span>
                      </div>

                      <div className="space-y-2">
                        {Object.entries(calculatePaymentOptions(data?.priceMDL, data?.priceEUR, data?.exchangeRate)).map(([key, option]) => (
                          <label
                            key={key}
                            className={`block cursor-pointer rounded-lg transition-all ${
                              selectedOption === key
                                ? 'bg-white border-2 border-green-500 shadow-sm'
                                : 'bg-white border border-gray-200 hover:border-green-200'
                            }`}
                          >
                            <input
                              type="radio"
                              name="payment"
                              className="hidden"
                              checked={selectedOption === key}
                              onChange={() => setSelectedOption(key)}
                            />

                            <div className="p-3 flex justify-between items-center">
                              <div className="flex items-center gap-2">
                                <div className="text-sm font-bold text-gray-900">{option.title}</div>
                                {option.discount > 0 && (
                                  <div className="bg-red-500 text-white text-xs px-2 py-0.5 rounded-full">
                                    -{option.discount}%
                                  </div>
                                )}
                              </div>

                              <div className="text-right">
                                <div className="text-sm font-bold text-green-600">
                                  {option.priceMDL.toLocaleString()} MDL
                                </div>
                                <div className="text-sm font-medium text-gray-500">
                                  {option.priceEUR.toLocaleString()} EUR
                                </div>
                                <div className="text-xs text-gray-500">
                                  {option.monthlyCostMDL.toLocaleString()} MDL / {t.payment.perMonth}
                                </div>
                                {option.monthlyCostEUR && (
                                  <div className="text-xs text-gray-500">
                                    {option.monthlyCostEUR.toLocaleString()} EUR / {t.payment.perMonth}
                                  </div>
                                )}
                                {option.savings > 0 && (
                                  <div className="text-xs text-green-600">
                                    {t.payment.savings} {option.savings.toLocaleString()} MDL
                                  </div>
                                )}
                              </div>
                            </div>
                          </label>
                        ))}
                      </div>
                    </div>

      {selectedOption && (
        <div ref={contactFormRef} className="space-y-3">
          <h3 className="text-sm font-medium text-gray-900">{t.payment.contacts.title}</h3>
          <div className="space-y-2">
            <div className="relative">
              <Phone className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              <input
                type="tel"
                value={contact.phone}
                onChange={(e) => setContact(prev => ({...prev, phone: e.target.value}))}
                className={`w-full pl-9 pr-3 py-2 text-sm rounded-lg border 
                  ${contact.phone ? 
                    (contact.phone.length >= 8 ? 'border-green-200 focus:ring-green-200' : 'border-red-200 focus:ring-red-200') 
                    : 'border-gray-200 focus:ring-green-200'
                  } 
                  focus:border-green-500 focus:ring-2`}
                placeholder={t.payment.contacts.phone}
              />
            </div>
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              <input
                type="email"
                value={contact.email}
                onChange={(e) => setContact(prev => ({...prev, email: e.target.value}))}
                className={`w-full pl-9 pr-3 py-2 text-sm rounded-lg border 
                  ${contact.email ? 
                    (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.email) ? 'border-green-200 focus:ring-green-200' : 'border-red-200 focus:ring-red-200') 
                    : 'border-gray-200 focus:ring-green-200'
                  } 
                  focus:border-green-500 focus:ring-2`}
                placeholder={t.payment.contacts.email}
              />
            </div>
          </div>

          <button 
            disabled={!isFormValid || isSubmitting}
            onClick={handleSubmitOrder}
            className={`w-full text-white rounded-lg py-3 px-4 text-sm font-medium 
              transition-all flex items-center justify-center gap-2
              ${isFormValid && !isSubmitting
                ? 'bg-green-600 hover:bg-green-700 cursor-pointer' 
                : 'bg-gray-400 cursor-not-allowed opacity-50'
              }`}
          >
            {isSubmitting ? (
              <>
                <span className="animate-spin">⚪</span>
                <span>{t.payment.processing}</span>
              </>
            ) : (
              <>
                <span>{t.payment.orderButton}</span>
                <ArrowRight className="w-4 h-4" />
              </>
            )}
          </button>
        </div>
      )}
      </div>
      );
      };

      export default InsurancePreviewRCAE;