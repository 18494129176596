import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, Link } from 'react-router-dom';
import { Phone, CheckCircle2, ChevronRight, Mail, MapPin, Car, Calculator, Shield, ArrowRight } from 'lucide-react';
import { translations } from './translations';
import { PromoSticker, PromoBanner } from './components/PromoElements';
import RCACalculator from './pages/RCACalculator';
import RCAECalculator from './pages/RCAECalculator';
import OrderSuccessRCA from './components/OrderSuccessRCA';
import OrderSuccessRCAE from './components/OrderSuccessRCAE';

// Utility Functions
const validatePhoneNumber = (phone) => {
  // Validates Moldovan phone numbers with various formats
  const phoneRegex = /^(?:\+373|00373|0)(?:[\s-]?\d{8})$/;
  return phoneRegex.test(phone.replace(/\s+/g, ''));
};

// Contact Form Component
const ContactForm = ({ lang, t }) => {
  const [formPhone, setFormPhone] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setFormSuccess(false);

    if (!formPhone) {
      setError(lang === 'ro' ? 'Introduceți numărul de telefon' : 'Введите номер телефона');
      return;
    }

    if (!/^(?:\+373|0)(?:[\s-]?\d{2}[\s-]?\d{3}[\s-]?\d{3})$/.test(formPhone)) {
      setError(lang === 'ro' ? 'Număr de telefon invalid' : 'Неверный номер телефона');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          phoneNumber: formPhone,
          lang 
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setFormSuccess(true);
        setFormPhone('');
        // Automatisch nach 3 Sekunden ausblenden
        setTimeout(() => {
          setFormSuccess(false);
        }, 3000);
      } else {
        throw new Error(data.message || 'Server error');
      }
    } catch (error) {
      console.error('Submit error:', error);
      setError(lang === 'ro' 
        ? 'A apărut o eroare. Vă rugăm să încercați din nou.'
        : 'Произошла ошибка. Пожалуйста, попробуйте еще раз.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative max-w-xl mx-auto mt-8">
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900">
          {lang === 'ro' ? 'Nu ai timp să calculezi online?' : 'Нет времени рассчитать онлайн?'}
        </h2>
        <p className="text-gray-600 mt-2">
          {lang === 'ro' 
            ? 'Lasă un număr de telefon și te vom contacta noi în cel mai scurt timp!' 
            : 'Оставьте номер телефона, и мы свяжемся с вами в ближайшее время!'}
        </p>
      </div>

      <form onSubmit={handleSubmit} className="relative">
        <div className="flex flex-col md:flex-row items-center gap-4 justify-center">
          <div className="w-full md:w-auto flex-1 max-w-md relative">
            <input
              type="tel"
              value={formPhone}
              onChange={(e) => setFormPhone(e.target.value)}
              placeholder={t.hero.phonePlaceholder}
              className={`w-full px-4 md:px-6 py-3 md:py-4 rounded-full 
                       bg-white/80 backdrop-blur-md shadow-xl 
                       focus:ring-2 ${error ? 'focus:ring-red-500 border-red-300' : 'focus:ring-blue-500'}
                       text-center text-base md:text-lg`}
              disabled={isSubmitting}
            />
            {error && (
              <div className="absolute -bottom-6 left-0 right-0 text-center text-red-500 text-sm">
                {error}
              </div>
            )}
          </div>
          <button
            type="submit"
            disabled={isSubmitting || !formPhone}
            className={`w-full md:w-auto bg-gradient-to-r from-blue-600 to-indigo-600 
                     text-white px-6 md:px-8 py-3 md:py-4 rounded-full font-semibold 
                     hover:from-blue-700 hover:to-indigo-700 transition-all shadow-xl 
                     hover:shadow-blue-200/50 flex items-center justify-center gap-2 
                     group whitespace-nowrap 
                     ${(isSubmitting || !formPhone) ? 'opacity-70 cursor-not-allowed' : ''}`}
          >
            <span>{isSubmitting ? '...' : t.hero.callButton}</span>
            <Phone className={`w-5 h-5 ${isSubmitting ? '' : 'group-hover:rotate-12'} transition-transform`} />
          </button>
        </div>

        {/* Neue, besser positionierte Erfolgsmeldung */}
        <div className={`absolute left-0 right-0 -bottom-16 z-[9999] transition-all duration-300 ${
          formSuccess ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-2'
        }`}>
          <div className="flex items-center justify-center gap-2 bg-blue-600 text-white py-3 px-6 rounded-full
                        shadow-lg mx-auto w-fit">
            <CheckCircle2 className="w-5 h-5" />
            <span className="font-medium">
              {lang === 'ro' 
                ? 'Solicitarea a fost trimisă cu succes!' 
                : 'Заявка успешно отправлена!'}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

// Insurance Card Component
const InsuranceCard = ({ type, content, onCalculateClick, className = '', mobileStyles = {} }) => {
  const isRCA = type === 'rca';
  const colors = isRCA ? {
    badge: 'bg-blue-100 text-blue-600',
    icon: 'text-blue-500',
    button: 'from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800'
  } : {
    badge: 'bg-green-100 text-green-600',
    icon: 'text-green-500',
    button: 'from-green-600 to-green-700 hover:from-green-700 hover:to-green-800'
  };

  return (
    <div className={`relative bg-white rounded-xl border ${isRCA ? 'border-blue-100' : 'border-green-100'} 
                    shadow-lg hover:shadow-xl transition-all duration-300 ${className}`}>
      <div className="h-full flex flex-col p-5">
        <div className={`inline-flex items-center gap-2 ${colors.badge} 
                      px-3 py-1.5 rounded-full font-medium w-fit 
                      ${mobileStyles.badge || ''}`}>
          {isRCA ? (
            <Car className="w-4 h-4 flex-shrink-0" />
          ) : (
            <Calculator className="w-4 h-4 flex-shrink-0" />
          )}
          <span className="whitespace-normal">{content.badge}</span>
        </div>

        <div className="flex flex-col flex-grow mt-4">
          <h3 className={`font-bold text-gray-900 ${mobileStyles.title || 'text-2xl'}`}>
            {content.title}
          </h3>

          <p className={`text-gray-600 mt-2 ${mobileStyles.description || 'text-base'}`}>
            {content.description}
          </p>

          <div className="flex items-start gap-2.5 text-gray-600 mt-4">
            <Shield className={`w-5 h-5 ${colors.icon} flex-shrink-0 mt-0.5`} />
            <span className={`${mobileStyles.feature || 'text-base'}`}>
              {content.feature}
            </span>
          </div>

          <button 
            onClick={onCalculateClick}
            className={`w-full bg-gradient-to-r ${colors.button} text-white 
                     rounded-lg mt-6 font-medium transition-all duration-300 
                     flex items-center justify-center gap-2 group
                     ${mobileStyles.button || 'text-base py-4'}`}>
            <span>{content.button}</span>
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </div>
      </div>
    </div>
  );
};

// Insurance Cards Section
const InsuranceCardsSection = ({ lang, isMobile }) => {
  const navigate = useNavigate();

  const content = {
    ro: {
      rca: {
        badge: "Calculator RCA",
        title: "Asigurare Auto RCA",
        description: "Calculează costul asigurării obligatorii pentru mașina ta în câteva minute.",
        feature: "Asigurare obligatorie de răspundere civilă",
        button: "Calculează RCA"
      },
      carteVerde: {
        badge: "Calculator Carte Verde",
        title: "Asigurare Carte Verde",
        description: "Calculează costul asigurării pentru călătoriile tale în străinătate.",
        feature: "Asigurare pentru călătorii în străinătate",
        button: "Calculează Carte Verde"
      }
    },
    ru: {
      rca: {
        badge: "Калькулятор ОСАГО",
        title: "Автострахование ОСАГО",
        description: "Рассчитайте стоимость обязательной страховки для вашего автомобиля за несколько минут.",
        feature: "Обязательное страхование гражданской ответственности",
        button: "Рассчитать ОСАГО"
      },
      carteVerde: {
        badge: "Калькулятор Зеленой Карты",
        title: "Страхование Зеленая Карта",
        description: "Рассчитайте стоимость страховки для ваших поездок за границу.",
        feature: "Страхование для поездок за границу",
        button: "Рассчитать Зеленую Карту"
      }
    }
  };

  const containerClasses = isMobile
    ? "bg-gradient-to-br from-blue-50 via-indigo-50 to-white py-6"
    : "w-full bg-gradient-to-br from-blue-50 via-indigo-50 to-white mt-36 sm:mt-40 md:mt-44 pt-8 sm:pt-10 md:pt-12";

  const cardsContainerClasses = isMobile
    ? "px-4 max-w-lg mx-auto"
    : "max-w-7xl mx-auto px-2 md:px-4";

  const cardsClasses = isMobile
    ? "flex flex-col gap-4"
    : "flex flex-row gap-2 md:gap-6 min-w-0";

  const cardClasses = isMobile
    ? "w-full transform transition-transform hover:scale-102 active:scale-98"
    : "w-full sm:w-1/2";

  const mobileCardStyles = isMobile ? {
    badge: "text-sm py-1.5 px-3",
    title: "text-xl",
    description: "text-sm",
    feature: "text-sm",
    button: "text-sm py-3"
  } : {};

  return (
    <section className={containerClasses}>
      <div className={cardsContainerClasses}>
        <div className={cardsClasses}>
          <InsuranceCard 
            type="rca" 
            content={content[lang].rca}
            onCalculateClick={() => navigate('/calculator-rca')}
            className={cardClasses}
            mobileStyles={mobileCardStyles}
          />
          
          <InsuranceCard 
            type="carteVerde" 
            content={content[lang].carteVerde}
            onCalculateClick={() => navigate('/calculator-rcae')}
            className={cardClasses}
            mobileStyles={mobileCardStyles}
          />
        </div>
      </div>
    </section>
  );
};

// Layout Component
const Layout = ({ lang, setLang }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const t = translations[lang];

  useEffect(() => {
    setIsAnimated(true);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth < 640;

  // Benefits Component
  const Benefits = () => (
    <div className="grid md:grid-cols-3 gap-4 md:gap-6 mt-16 mb-8 md:mb-12">
      {t.benefits.map((benefit, i) => (
        <div 
          key={i}
          className={`bg-white/80 backdrop-blur-md p-3 md:p-4 rounded-xl shadow-lg flex items-center gap-3 transition-all duration-700 delay-${i * 200} ${
            isAnimated ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
          }`}
        >
          <CheckCircle2 className="w-6 h-6 text-green-500 flex-shrink-0" />
          <span className="text-gray-700 text-sm md:text-base">{benefit}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div className="relative w-full min-h-screen bg-white overflow-x-hidden">
      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-white/90 backdrop-blur-lg shadow-md">
        <div className="max-w-7xl mx-auto px-4 md:px-6 h-20 flex items-center justify-between">
          <Link to="/" className="flex items-center gap-2 md:gap-3">
            <div className="relative w-10 md:w-12 h-10 md:h-12 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-xl flex items-center justify-center transform rotate-12">
              <div className="absolute inset-0.5 bg-white rounded-lg rotate-6" />
                <span className="relative text-xl md:text-2xl font-bold text-blue-600 rotate-[-18deg]">RDM</span>
              </div>
              <div className="text-lg md:text-xl font-bold text-blue-600">FINANCE SOLUTIONS</div>
              </Link>

              <div className="flex items-center gap-4 md:gap-6">
              <div className="hidden md:flex gap-6 md:gap-8">
                <a href="#" className="text-gray-600 hover:text-blue-600">{t.nav.about}</a>
                <a href="#" className="text-gray-600 hover:text-blue-600">{t.nav.services}</a>
                <a href="#" className="text-gray-600 hover:text-blue-600">{t.nav.calculator}</a>
                <a href="#" className="text-gray-600 hover:text-blue-600">{t.nav.contact}</a>
              </div>

              <div className="flex items-center gap-4 border-l pl-4 md:pl-6">
                <button
                  onClick={() => setLang('ro')}
                  className={`text-sm font-medium ${lang === 'ro' ? 'text-blue-600' : 'text-gray-500'}`}
                >
                  RO
                </button>
                <button
                  onClick={() => setLang('ru')}
                  className={`text-sm font-medium ${lang === 'ru' ? 'text-blue-600' : 'text-gray-500'}`}
                >
                  RU
                </button>
              </div>
              </div>
              </div>
              </nav>

              {/* Promo Banner */}
              <PromoBanner lang={lang} />

              {/* Insurance Cards for Desktop */}
              {!isMobile && <InsuranceCardsSection lang={lang} isMobile={false} />}

              {/* Hero Section */}
              <section className="relative w-full pt-36 sm:pt-12 pb-24 md:pb-32 flex items-center justify-center bg-gradient-to-br from-blue-50 via-indigo-50 to-white">
              <PromoSticker lang={lang} />

              <div className="absolute inset-0">
              <div 
              className={`absolute w-96 h-96 bg-blue-200/20 rounded-full -left-48 top-1/4 blur-3xl transition-all duration-1000 ${
                isAnimated ? 'opacity-100 translate-x-12' : 'opacity-0 -translate-x-12'
              }`}
              />
              <div 
              className={`absolute w-96 h-96 bg-indigo-200/20 rounded-full -right-48 top-1/2 blur-3xl transition-all duration-1000 delay-300 ${
                isAnimated ? 'opacity-100 -translate-x-12' : 'opacity-0 translate-x-12'
              }`}
              />
              </div>

              <div className="relative w-full max-w-5xl mx-auto px-4 md:px-6 text-center">
              <div className={`space-y-6 md:space-y-8 transition-all duration-1000 ${
              isAnimated ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'
              }`}>
              <div className="inline-block">
                <div className="bg-blue-100 text-blue-600 px-4 md:px-6 py-2 rounded-full text-base md:text-lg font-medium">
                  {t.hero.badge}
                </div>
              </div>

              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 leading-tight">
                {t.hero.title}
                <span className="bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent block mt-2">
                  {t.hero.titleHighlight}
                </span>
              </h1>

              <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto">
                {t.hero.description}
              </p>
              </div>

              {isMobile ? (
              <div className="space-y-8">
                <InsuranceCardsSection lang={lang} isMobile={true} />
                <ContactForm lang={lang} t={t} />
                <Benefits />
              </div>
              ) : (
              <div className="space-y-8">
                <ContactForm lang={lang} t={t} />
                <Benefits />
              </div>
              )}
              </div>
              </section>

              {/* Footer */}
              <footer className="relative w-full bg-gray-900 text-gray-300 py-8 md:py-12">
              <div className="max-w-7xl mx-auto px-4 md:px-6">
              <div className="grid md:grid-cols-3 gap-8 md:gap-12">
              {/* Company */}
              <div className="space-y-4">
                <div className="flex items-center gap-3">
                  <div className="relative w-10 md:w-12 h-10 md:h-12 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-xl flex items-center justify-center transform rotate-12">
                    <div className="absolute inset-0.5 bg-white rounded-lg rotate-6" />
                    <span className="relative text-xl md:text-2xl font-bold text-blue-600 rotate-[-18deg]">RDM</span>
                  </div>
                  <div className="text-lg md:text-xl font-bold text-white">FINANCE SOLUTIONS</div>
                </div>
              </div>

              {/* Contact */}
              <div className="space-y-4">
                <div className="space-y-3">
                  <div className="flex gap-3 text-gray-400">
                    <MapPin className="w-5 h-5 text-blue-400 flex-shrink-0" />
                    <span className="text-sm md:text-base">{t.footer.address}</span>
                  </div>
                  <a href={`tel:${t.footer.phone}`} className="flex gap-3 text-gray-400 hover:text-blue-400">
                    <Phone className="w-5 h-5" />
                    <span className="text-sm md:text-base">{t.footer.phone}</span>
                  </a>
                  <a href={`mailto:${t.footer.email}`} className="flex gap-3 text-gray-400 hover:text-blue-400">
                    <Mail className="w-5 h-5" />
                    <span className="text-sm md:text-base">{t.footer.email}</span>
                  </a>
                </div>
              </div>

              {/* Links */}
              <div className="space-y-3">
                {t.footer.links.map(link => (
                  <a key={link} href="#" className="flex items-center gap-2 text-gray-400 hover:text-blue-400">
                    <ChevronRight className="w-4 h-4" />
                    <span className="text-sm md:text-base">{link}</span>
                  </a>
                ))}
              </div>
              </div>

              <div className="border-t border-gray-800 mt-8 md:mt-12 pt-6 md:pt-8">
              <div className="text-center text-gray-400 text-xs md:text-sm">
                {t.footer.rights}
              </div>
              </div>
              </div>
              </footer>
              </div>
              );
              };

              // Main App Component
              const App = () => {
              const [lang, setLang] = useState('ro');

              return (
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Layout lang={lang} setLang={setLang} />} />
                    <Route path="/calculator-rca" element={<RCACalculator lang={lang} setLang={setLang} />} />
                    <Route path="/calculator-rcae" element={<RCAECalculator lang={lang} setLang={setLang} />} />
                    <Route path="/order-success" element={<OrderSuccessRCA lang={lang} />} />
                    <Route path="/order-success-rcae" element={<OrderSuccessRCAE lang={lang} />} />
                  </Routes>
                </BrowserRouter>
              );
              };

              export default App;