// translations.js
export const translations = {
  ro: {
    nav: {
      about: "Despre Noi",
      services: "Servicii",
      calculator: "Calculator",
      contact: "Contact"
    },
    hero: {
      badge: "Partner Oficial al Brokerului de Asigurări",
      title: "Transformă Asigurările în",
      titleHighlight: "Rate GRATUIT",
      description: "Serviciu de factoring complet gratuit pentru tine. Toate costurile sunt acoperite de brokerul nostru partener.",
      phonePlaceholder: "Introduceți numărul de telefon",
      callButton: "Te Sunăm Noi",
      successMessage: "Vă vom contacta în curând!"
    },
    preview: {
      title: "Detalii Asigurare",
      back: "Înapoi",
      details: "Detalii poliță",
      vehicle: "Automobil",
      plateNumber: "Număr înmatriculare",
      client: "Client",
      period: "Perioada asigurării",
      validityTerm: "Termenul de valabilitate", // NEU
        validityPeriods: {                        // NEU
          15: "15 zile",
          30: "1 lună",
          60: "2 luni",
          90: "3 luni",
          120: "4 luni",
          150: "5 luni",
          180: "6 luni",
          210: "7 luni",
          240: "8 luni",
          270: "9 luni",
          300: "10 luni",
          330: "11 luni",
          365: "12 luni"
        },
      benefits: {
        support: "Suport 24/7",
        coverage: "Acoperire completă",
        processing: "Procesare rapidă"
      },
      payment: {
        standardPrice: "Preț standard:",
        selectPeriod: "Selectați perioada de plată:",
        options: {
          oneMonth: "1 lună",
          twoMonths: "2 luni",
          threeMonths: "3 luni"
        },
        perMonth: "lună",
        savings: "Economisiți",
        contacts: {
          phone: "Număr de telefon",
          email: "Adresa de email"
        },
        orderButton: "Comandă asigurarea"
      }
    },
    benefits: [
      "Aprobare în maxim 2 ore",
      "Zero costuri de factoring",
      "Asigurare activă instant"
    ],
    footer: {
      address: "sec. Ciocana, str. Otovasca, 1,\nMD2023 mun. Chişinău",
      phone: "+373 123 45 678",
      email: "contact@rdmfinance.md",
      links: [
        'Despre Noi',
        'Servicii',
        'Calculator',
        'Termeni și Condiții',
        'Politica de Confidențialitate'
      ],
      rights: "© 2024 RDM FINANCE SOLUTIONS. Toate drepturile rezervate."
    }
  },
  ru: {
    nav: {
      about: "О Нас",
      services: "Услуги",
      calculator: "Калькулятор",
      contact: "Контакты"
    },
    hero: {
      badge: "Официальный Партнер Страхового Брокера",
      title: "Оформите Страховку в",
      titleHighlight: "РАССРОЧКУ БЕСПЛАТНО",
      description: "Полностью бесплатный факторинговый сервис для вас. Все расходы покрываются нашим брокером-партнером.",
      phonePlaceholder: "Введите номер телефона",
      callButton: "Мы Вам Перезвоним",
      successMessage: "Мы скоро с вами свяжемся!"
    },
    preview: {
      title: "Детали Страховки",
      back: "Назад",
      details: "Детали полиса",
      vehicle: "Автомобиль",
      plateNumber: "Номерной знак",
      client: "Клиент",
      period: "Период страхования",
      validityTerm: "Срок действия",           // NEU
        validityPeriods: {                       // NEU
          15: "15 дней",
          30: "1 месяц",
          60: "2 месяца",
          90: "3 месяца",
          120: "4 месяца",
          150: "5 месяцев",
          180: "6 месяцев",
          210: "7 месяцев",
          240: "8 месяцев",
          270: "9 месяцев",
          300: "10 месяцев",
          330: "11 месяцев",
          365: "12 месяцев"
        },
      benefits: {
        support: "Поддержка 24/7",
        coverage: "Полное покрытие",
        processing: "Быстрая обработка"
      },
      payment: {
        standardPrice: "Стандартная цена:",
        selectPeriod: "Выберите период оплаты:",
        options: {
          oneMonth: "1 месяц",
          twoMonths: "2 месяца",
          threeMonths: "3 месяца"
        },
        perMonth: "месяц",
        savings: "Экономия",
        contacts: {
          phone: "Номер телефона",
          email: "Электронная почта"
        },
        orderButton: "Заказать страховку"
      }
    },
    benefits: [
      "Одобрение максимум за 2 часа",
      "Нулевые затраты на факторинг",
      "Мгновенная активация страховки"
    ],
    footer: {
      address: "сек. Чокана, ул. Отоваска, 1,\nMD2023 мун. Кишинёв",
      phone: "+373 123 45 678",
      email: "contact@rdmfinance.md",
      links: [
        'О Нас',
        'Услуги',
        'Калькулятор',
        'Условия и Положения',
        'Политика Конфиденциальности'
      ],
      rights: "© 2024 RDM FINANCE SOLUTIONS. Все права защищены."
    }
  }
};
