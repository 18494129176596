import React from 'react';
import { Check, Home, Calendar, Shield, Car } from 'lucide-react';
import { Link, useLocation, Navigate } from 'react-router-dom';

const OrderSuccessRCA = ({ lang = 'ro' }) => {
  const location = useLocation();
  const orderData = location.state?.orderData;

  if (!orderData) {
    return <Navigate to="/" />;
  }

  const translations = {
    ro: {
      title: "Vă mulțumim pentru comandă!",
      orderNumber: "Numărul poliței RCA este:",
      emailInfo: "În curând veți primi un email cu polița RCA și detaliile comenzii.",
      backHome: "Înapoi la pagina principală",
      details: {
        title: "Detalii poliță RCA",
        vehicle: "Automobil",
        plate: "Număr înmatriculare",
        period: "Perioada asigurării",
        price: "Suma totală",
        payment: "Modalitate plată"
      },
      paymentOptions: {
        oneMonth: "1 lună (-15%)",
        twoMonths: "2 luni (-7.5%)",
        threeMonths: "3 luni"
      }
    },
    ru: {
      title: "Спасибо за ваш заказ!",
      orderNumber: "Номер полиса ОСАГО:",
      emailInfo: "Вскоре вы получите электронное письмо с полисом ОСАГО и деталями заказа.",
      backHome: "Вернуться на главную",
      details: {
        title: "Детали полиса ОСАГО",
        vehicle: "Автомобиль",
        plate: "Номерной знак",
        period: "Период страхования",
        price: "Итоговая сумма",
        payment: "Способ оплаты"
      },
      paymentOptions: {
        oneMonth: "1 месяц (-15%)",
        twoMonths: "2 месяца (-7.5%)",
        threeMonths: "3 месяца"
      }
    }
  };

  const t = translations[lang];

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat(lang === 'ro' ? 'ro' : 'ru', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(new Date(dateString));
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-2 py-4 sm:px-4">
        <div className="w-full max-w-lg bg-white rounded-xl sm:rounded-2xl shadow-sm p-4 sm:p-6 md:p-8">
          {/* Success Header */}
          <div className="text-center mb-6 sm:mb-8">
            <div className="mx-auto w-12 h-12 sm:w-16 sm:h-16 bg-green-100 rounded-full flex items-center justify-center mb-3 sm:mb-4">
              <Check className="w-6 h-6 sm:w-8 sm:h-8 text-green-600" />
            </div>

            <h1 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
              {t.title}
            </h1>

            <div className="text-xs sm:text-sm text-gray-600 mb-2">
              {t.orderNumber}
            </div>

            <div className="bg-gray-50 rounded-lg p-2 sm:p-3 mb-2">
              <code className="text-xs sm:text-sm font-mono text-blue-600 break-all">
                {orderData.documentNumber}
              </code>
            </div>

            <p className="text-xs sm:text-sm text-gray-600">
              {t.emailInfo}
            </p>
          </div>

          {/* Order Details */}
          <div className="bg-gray-50 rounded-lg sm:rounded-xl p-3 sm:p-4 mb-4 sm:mb-6">
            <div className="flex items-center gap-2 mb-3 sm:mb-4 pb-2 border-b border-gray-200">
              <Shield className="w-4 h-4 sm:w-5 sm:h-5 text-blue-600" />
              <h2 className="text-base sm:text-lg font-semibold text-gray-900">{t.details.title}</h2>
            </div>

            <div className="space-y-2 sm:space-y-3">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-1.5 sm:gap-2 text-gray-600">
                  <Car className="w-3 h-3 sm:w-4 sm:h-4" />
                  <span className="text-xs sm:text-sm">{t.details.vehicle}</span>
                </div>
                <span className="text-xs sm:text-sm font-medium">{orderData.carModel}</span>
              </div>

              <div className="flex justify-between items-center">
                <span className="text-xs sm:text-sm text-gray-600">{t.details.plate}</span>
                <span className="text-xs sm:text-sm font-medium">{orderData.plateNumber}</span>
              </div>

              <div className="flex justify-between items-center">
                <div className="flex items-center gap-1.5 sm:gap-2 text-gray-600">
                  <Calendar className="w-3 h-3 sm:w-4 sm:h-4" />
                  <span className="text-xs sm:text-sm">{t.details.period}</span>
                </div>
                <span className="text-xs sm:text-sm font-medium">
                  {formatDate(orderData.startDate)} - {formatDate(orderData.expirationDate)}
                </span>
              </div>

              <div className="flex justify-between items-center pt-2 border-t border-gray-200">
                <span className="text-xs sm:text-sm text-gray-600">{t.details.price}</span>
                <span className="text-base sm:text-lg font-bold text-blue-600">
                  {(orderData.price || orderData.finalPrice).toLocaleString()} MDL
                </span>
              </div>
            </div>
          </div>

          {/* Back Button */}
          <Link 
            to="/"
            className="w-full inline-flex items-center justify-center gap-1.5 sm:gap-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg sm:rounded-xl px-4 sm:px-6 py-2.5 sm:py-3 text-xs sm:text-sm font-medium transition-colors"
          >
            <Home className="w-3 h-3 sm:w-4 sm:h-4" />
            <span>{t.backHome}</span>
          </Link>
        </div>
      </div>
      );
      };

      export default OrderSuccessRCA;