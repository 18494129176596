import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Car, ArrowRight, AlertCircle, Phone } from 'lucide-react';
import InsurancePreview from '../components/InsurancePreview';

const translations = {
  ro: {
    nav: {
      about: "Despre Noi",
      services: "Servicii",
      calculator: "Calculator",
      contact: "Contact"
    },
    badge: "Calculator RCA",
    title: "Asigurare Auto RCA",
    description: "Calculează costul asigurării obligatorii pentru mașina ta în câteva minute.",
    form: {
      idno: 'IDNO/IDNP',
      idnoPlaceholder: 'Introduceți IDNO/IDNP (13 cifre)',
      vehicleDoc: 'Număr certificat înmatriculare',
      vehicleDocPlaceholder: 'Introduceți numărul certificatului',
      startDate: 'Data început asigurare',
      operationMode: 'Mod de operare',
      operationModes: {
        normal: 'Mod obișnuit',
        public_transport: 'Microbus pe rută',
        public_transport_interurban: 'Transport interurban autorizat'
      },
      gdprConsent: 'Sunt de acord cu prelucrarea datelor cu caracter personal',
      termsConsent: 'Accept termenii și condițiile'
    },
    button: {
      calculate: "Calculează RCA",
      loading: "Se calculează..."
    },
    errors: {
      required: 'Acest câmp este obligatoriu',
      idnoFormat: 'IDNO/IDNP trebuie să conțină exact 13 cifre',
      vehicleDocRequired: 'Introduceți numărul certificatului',
      consents: 'Vă rugăm să acceptați termenii și condițiile',
      serverError: 'Vă rugăm să verificați datele introduse și să încercați din nou. Dacă problema persistă, vă rugăm să contactați administratorul.'
    }
  },
  ru: {
    nav: {
      about: "О Нас",
      services: "Услуги",
      calculator: "Калькулятор",
      contact: "Контакты"
    },
    badge: "Калькулятор ОСАГО",
    title: "Автострахование ОСАГО",
    description: "Рассчитайте стоимость обязательной страховки для вашего автомобиля за несколько минут.",
    form: {
      idno: 'IDNO/IDNP',
      idnoPlaceholder: 'Введите IDNO/IDNP (13 цифр)',
      vehicleDoc: 'Номер свидетельства о регистрации',
      vehicleDocPlaceholder: 'Введите номер свидетельства',
      startDate: 'Дата начала страховки',
      operationMode: 'Режим эксплуатации',
      operationModes: {
        normal: 'Обычный режим',
        public_transport: 'Маршрутное такси',
        public_transport_interurban: 'Междугородний транспорт'
      },
      gdprConsent: 'Я согласен на обработку персональных данных',
      termsConsent: 'Я принимаю условия и положения'
    },
    button: {
      calculate: "Рассчитать ОСАГО",
      loading: "Загрузка..."
    },
    errors: {
      required: 'Это поле обязательно',
      idnoFormat: 'IDNO/IDNP должен содержать ровно 13 цифр',
      vehicleDocRequired: 'Введите номер свидетельства',
      consents: 'Пожалуйста, примите условия',
      serverError: 'Пожалуйста, проверьте введенные данные и попробуйте снова. Если проблема сохраняется, свяжитесь с администратором.'
    }
  }
};

const RCACalculator = ({ lang, setLang }) => {
  const [step, setStep] = useState(1);
  const [insuranceData, setInsuranceData] = useState(null);
  const t = translations[lang];

  const [formData, setFormData] = useState({
    idno: '',
    vehicleDoc: '',
    startDate: new Date().toISOString().split('T')[0],
    operationMode: 'normal',
    gdprConsent: false,
    termsConsent: false
  });

  const [error, setError] = useState(null);
  const [isCompany, setIsCompany] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const hasValidIdno = !formData.idno || formData.idno.length === 13;
    const hasRequiredFields = formData.idno && formData.vehicleDoc && formData.startDate;
    const hasConsents = formData.gdprConsent && formData.termsConsent;

    setError(formData.idno && !hasValidIdno ? t.errors.idnoFormat : null);
    setIsFormValid(hasValidIdno && hasRequiredFields && hasConsents);
  }, [formData, t.errors.idnoFormat]);

  const handleInput = (field, value) => {
    if (field === 'idno') {
      const numericValue = value.slice(0, 13).replace(/\D/g, '');
      setIsCompany(numericValue.startsWith('1'));
      value = numericValue;
    }
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const generateDocumentNumber = (certificateNumber) => {
    const now = new Date();
    const isInDST = () => {
      const year = now.getUTCFullYear();
      const dstStart = new Date(Date.UTC(year, 2, 31));
      dstStart.setUTCDate(dstStart.getUTCDate() - dstStart.getUTCDay());
      const dstEnd = new Date(Date.UTC(year, 9, 31));
      dstEnd.setUTCDate(dstEnd.getUTCDate() - dstEnd.getUTCDay());
      return now >= dstStart && now < dstEnd;
    };

    const chisinauOffset = isInDST() ? 3 : 2;
    const chisinauTime = new Date(now.getTime());
    chisinauTime.setHours(now.getUTCHours() + chisinauOffset);

    const day = String(chisinauTime.getDate()).padStart(2, '0');
    const month = String(chisinauTime.getMonth() + 1).padStart(2, '0');
    const year = chisinauTime.getFullYear().toString().slice(2);
    const hours = String(chisinauTime.getHours()).padStart(2, '0');
    const minutes = String(chisinauTime.getMinutes()).padStart(2, '0');

    return `RCA-${day}${month}${year}-${hours}:${minutes}-${certificateNumber.replace(/\D/g, '')}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid || isLoading) return;

    setIsLoading(true);
    try {
      const response = await fetch('https://europe-west3-iasigmd.cloudfunctions.net/getprice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            type: "rca",
            values: {
              idnp: formData.idno,
              certificateNumber: formData.vehicleDoc,
              insuranceValidity: 365,
              category: "M1",
              registrationCountry: "MD",
              operatingMode: isCompany ? formData.operationMode : 'normal',
              startDate: formData.startDate
            }
          }
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const result = await response.json();

      const processedData = {
        documentNumber: generateDocumentNumber(formData.vehicleDoc),
        carModel: result.result.carModel,
        plateNumber: result.result.plateNumber,
        idnp: formData.idno,
        contractorName: result.result.contractorName,
        contractorType: result.result.contractorType,
        startDate: formData.startDate,
        expirationDate: result.result.expirationDate,
        price: result.result.priceMDL,
        validData: result.result.validData,
        isTrailer: result.result.isTrailer,
        isVehicleProbe: result.result.isVehicleProbe
      };

      console.log('Generated document number:', processedData.documentNumber);
      console.log('Processed insurance data:', processedData);

      setInsuranceData(processedData);
      setStep(2);

    } catch (err) {
      console.error('API Error:', err);
      setError(t.errors.serverError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full bg-[#F8FAFC] min-h-screen">
      <nav className="fixed w-full z-50 bg-white/90 backdrop-blur-lg shadow-md">
        <div className="max-w-7xl mx-auto px-4 md:px-6 h-20 flex items-center justify-between">
          <Link to="/" className="flex items-center gap-2 md:gap-3">
            <div className="relative w-10 md:w-12 h-10 md:h-12 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-xl flex items-center justify-center transform rotate-12">
              <div className="absolute inset-0.5 bg-white rounded-lg rotate-6" />
              <span className="relative text-xl md:text-2xl font-bold text-blue-600 rotate-[-18deg]">RDM</span>
            </div>
            <div className="text-lg md:text-xl font-bold text-blue-600">FINANCE SOLUTIONS</div>
          </Link>

          <div className="flex items-center gap-4 md:gap-6">
            <div className="hidden md:flex gap-6 md:gap-8">
              {Object.entries(t.nav).map(([key, value]) => (
                <a key={key} href="#" className="text-gray-600 hover:text-blue-600 whitespace-nowrap">{value}</a>
              ))}
            </div>

            <div className="flex items-center gap-4 border-l pl-4 md:pl-6">
              <button
                onClick={() => setLang('ro')}
                className={`text-sm font-medium ${lang === 'ro' ? 'text-blue-600' : 'text-gray-500'}`}
              >
                RO
              </button>
              <button
                onClick={() => setLang('ru')}
                className={`text-sm font-medium ${lang === 'ru' ? 'text-blue-600' : 'text-gray-500'}`}
              >
                RU
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-lg mx-auto px-3 py-6 pt-28">
        {step === 1 ? (
          <div className="w-full bg-white rounded-2xl p-6 shadow-sm">
            <div className="space-y-3 mb-6">
              <div className="flex items-center gap-1.5">
                <Car className="w-4 h-4 text-blue-600" />
                <span className="text-xs bg-blue-100 text-blue-600 px-2.5 py-0.5 rounded-full">
                  {t.badge}
                </span>
              </div>

              <h1 className="text-xl font-bold text-gray-900">{t.title}</h1>
              <p className="text-sm text-gray-600">{t.description}</p>
            </div>

            {error && (
              <div className="mb-5 flex items-center gap-1.5 text-red-600 bg-red-50 p-3 rounded-lg">
                <AlertCircle className="w-4 h-4 flex-shrink-0" />
                <span className="text-xs">{error}</span>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-5">
              {[
                { name: 'idno', type: 'text', label: t.form.idno, placeholder: t.form.idnoPlaceholder },
                { name: 'vehicleDoc', type: 'text', label: t.form.vehicleDoc, placeholder: t.form.vehicleDocPlaceholder },
                { name: 'startDate', type: 'date', label: t.form.startDate }
              ].map(field => (
                <div key={field.name} className="space-y-1.5">
                  <label className="block text-xs text-gray-700">{field.label}</label>
                  <input
                    type={field.type}
                    value={formData[field.name]}
                    onChange={(e) => handleInput(field.name, e.target.value)}
                    placeholder={field.placeholder}
                    min={field.type === 'date' ? new Date().toISOString().split('T')[0] : undefined}
                    className={`w-full px-3 py-2 rounded-lg border ${
                      error && field.name === 'idno' ? 'border-red-300 focus:ring-red-200' : 'border-gray-200 focus:ring-blue-200'
                    } focus:border-blue-500 focus:ring-1 text-sm`}
                  />
                </div>
              ))}

              {isCompany && (
                <div className="space-y-1.5">
                  <label className="block text-xs text-gray-700">{t.form.operationMode}</label>
                  <select
                    value={formData.operationMode}
                    onChange={(e) => handleInput('operationMode', e.target.value)}
                    className="w-full px-3 py-2 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-200 text-sm"
                  >
                    {Object.entries(t.form.operationModes).map(([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                    ))}
                  </select>
                </div>
              )}

              <div className="space-y-2.5">
                {['gdprConsent', 'termsConsent'].map(consent => (
                  <label key={consent}
                    className="flex items-center gap-1.5 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={formData[consent]}
                        onChange={(e) => handleInput(consent, e.target.checked)}
                        className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <span className="text-xs text-gray-600">{t.form[consent]}</span>
                    </label>
                    ))}
                    </div>

                    <button
                    type="submit"
                    disabled={!isFormValid || isLoading}
                    className={`w-full bg-blue-600 hover:bg-blue-700 text-white rounded-lg py-2.5 px-3 
                    text-sm font-medium transition-colors flex items-center justify-center gap-1.5 
                    ${(!isFormValid || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                    <span>{isLoading ? t.button.loading : t.button.calculate}</span>
                    <ArrowRight className="w-4 h-4" />
                    </button>
                    </form>
                    </div>
                    ) : (
                    <InsurancePreview 
                    data={insuranceData}
                    lang={lang}
                    onBack={() => setStep(1)}
                    />
                    )}
                    </div>
                    </div>
                    );
                    };

                    export default RCACalculator;