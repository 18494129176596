import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Car, ArrowRight, AlertCircle, Phone, Globe } from 'lucide-react';
import InsurancePreviewRCAE from '../components/InsurancePreviewRCAE';

// Füge diese Formatierungsfunktion am Anfang von RCAECalculator.jsx hinzu
const formatNumber = (number) => {
  if (number === null || number === undefined) return "0.00";
  return Number(number).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const translations = {
  ro: {
    nav: {
      about: "Despre Noi",
      services: "Servicii",
      calculator: "Calculator",
      contact: "Contact"
    },
    badge: "Calculator Carte Verde",
    title: "Asigurare Carte Verde",
    description: "Calculează costul asigurării pentru călătoriile tale în străinătate.",
    form: {
      idno: 'IDNO/IDNP',
      idnoPlaceholder: 'Introduceți IDNO/IDNP (13 cifre)',
      vehicleDoc: 'Număr certificat înmatriculare',
      vehicleDocPlaceholder: 'Introduceți numărul certificatului',
      towingDoc: 'Număr certificat remorcă (opțional)',
      towingDocPlaceholder: 'Introduceți numărul certificatului remorcii',
      startDate: 'Data început asigurare',
      period: 'Perioada asigurării',
      zone: 'Zona de deplasare',
      zones: {
        3: 'Toate țările sistemului Carte Verde (UE)',
        1: 'Ucraina'
      
      },
      periods: {
        15: '15 zile',
        30: '1 lună',
        60: '2 luni',
        90: '3 luni',
        120: '4 luni',
        150: '5 luni',
        180: '6 luni',
        210: '7 luni',
        240: '8 luni',
        270: '9 luni',
        300: '10 luni',
        330: '11 luni',
        365: '12 luni'
      },
      gdprConsent: 'Sunt de acord cu prelucrarea datelor cu caracter personal',
      termsConsent: 'Accept termenii și condițiile'
    },
    button: {
      calculate: "Calculează Carte Verde",
      loading: "Se calculează..."
    },
    errors: {
      required: 'Acest câmp este obligatoriu',
      idnoFormat: 'IDNO/IDNP trebuie să conțină exact 13 cifre',
      vehicleDocRequired: 'Introduceți numărul certificatului',
      consents: 'Vă rugăm să acceptați termenii și condițiile',
      serverError: 'Vă rugăm să verificați datele introduse și să încercați din nou.'
    }
  },
  ru: {
    nav: {
      about: "О Нас",
      services: "Услуги",
      calculator: "Калькулятор",
      contact: "Контакты"
    },
    badge: "Калькулятор Зеленой Карты",
    title: "Страхование Зеленая Карта",
    description: "Рассчитайте стоимость страховки для ваших поездок за границу.",
    form: {
      idno: 'IDNO/IDNP',
      idnoPlaceholder: 'Введите IDNO/IDNP (13 цифр)',
      vehicleDoc: 'Номер свидетельства о регистрации',
      vehicleDocPlaceholder: 'Введите номер свидетельства',
      towingDoc: 'Номер свидетельства прицепа (необязательно)',
      towingDocPlaceholder: 'Введите номер свидетельства прицепа',
      startDate: 'Дата начала страховки',
      period: 'Период страхования',
      zone: 'Зона передвижения',
      zones: {
        3: 'Все страны системы Зеленая Карта (ЕС)',
        1: 'Украина'
        
      },
      periods: {
        15: '15 дней',
        30: '1 месяц',
        60: '2 месяца',
        90: '3 месяца',
        120: '4 месяца',
        150: '5 месяцев',
        180: '6 месяцев',
        210: '7 месяцев',
        240: '8 месяцев',
        270: '9 месяцев',
        300: '10 месяцев',
        330: '11 месяцев',
        365: '12 месяцев'
      },
      gdprConsent: 'Я согласен на обработку персональных данных',
      termsConsent: 'Я принимаю условия и положения'
    },
    button: {
      calculate: "Рассчитать Зеленую Карту",
      loading: "Загрузка..."
    },
    errors: {
      required: 'Это поле обязательно',
      idnoFormat: 'IDNO/IDNP должен содержать ровно 13 цифр',
      vehicleDocRequired: 'Введите номер свидетельства',
      consents: 'Пожалуйста, примите условия',
      serverError: 'Пожалуйста, проверьте введенные данные и попробуйте снова.'
    }
  }
};

const RCAECalculator = ({ lang, setLang }) => {
  const [step, setStep] = useState(1);
  const [insuranceData, setInsuranceData] = useState(null);
  const t = translations[lang];

  const [formData, setFormData] = useState({
    idno: '',
    vehicleDoc: '',
    towingDoc: '',
    startDate: new Date().toISOString().split('T')[0],
    insuranceValidity: '15',
    zone: '3',
    gdprConsent: false,
    termsConsent: false
  });

  const [error, setError] = useState(null);
  const [isCompany, setIsCompany] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const hasValidIdno = !formData.idno || formData.idno.length === 13;
    const hasRequiredFields = formData.idno && formData.vehicleDoc && formData.startDate && 
                            formData.insuranceValidity && formData.zone;
    const hasConsents = formData.gdprConsent && formData.termsConsent;

    setError(formData.idno && !hasValidIdno ? t.errors.idnoFormat : null);
    setIsFormValid(hasValidIdno && hasRequiredFields && hasConsents);
  }, [formData, t.errors.idnoFormat]);

  const handleInput = (field, value) => {
    if (field === 'idno') {
      const numericValue = value.slice(0, 13).replace(/\D/g, '');
      setIsCompany(numericValue.startsWith('1'));
      value = numericValue;
    }
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const generateDocumentNumber = (certificateNumber) => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear().toString().slice(2);
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `RCAE-${day}${month}${year}-${hours}:${minutes}-${certificateNumber.replace(/\D/g, '')}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid || isLoading) return;

    setIsLoading(true);
    try {
      const response = await fetch('https://europe-west3-iasigmd.cloudfunctions.net/getprice', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            type: "green-card",
            values: {
              idnp: formData.idno,
              certificateNumber: formData.vehicleDoc,
              towingCertificateNumber: formData.towingDoc || '',
              insuranceValidity: parseInt(formData.insuranceValidity),
              ownership: null,
              zone: formData.zone
            }
          }
        })
      });

      if (!response.ok) throw new Error(`HTTP error ${response.status}`);

      const result = await response.json();

      const processedData = {
        documentNumber: generateDocumentNumber(formData.vehicleDoc),
        carModel: result.result.carModel,
        plateNumber: result.result.plateNumber,
        towingModel: result.result.towingModel,
        towingPlateNumber: result.result.towingPlateNumber,
        idnp: formData.idno,
        contractorName: result.result.contractorName,
        contractorType: result.result.contractorType,
        startDate: formData.startDate,
        insuranceValidity: parseInt(formData.insuranceValidity),
        zone: formData.zone,
        rawPriceMDL: Number(result.result.priceMDL),
        rawPriceEUR: Number(result.result.priceEUR),
        rawExchangeRate: Number(result.result.exchangeRate),
        priceMDL: formatNumber(result.result.priceMDL),
        priceEUR: formatNumber(result.result.priceEUR),
        exchangeRate: formatNumber(result.result.exchangeRate),
        category: result.result.category
      };

      setInsuranceData(processedData);
      setStep(2);

    } catch (err) {
      console.error('API Error:', err);
      setError(t.errors.serverError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full bg-[#F8FAFC] min-h-screen">
      <nav className="fixed w-full z-50 bg-white/90 backdrop-blur-lg shadow-md">
        <div className="max-w-7xl mx-auto px-4 md:px-6 h-20 flex items-center justify-between">
          <Link to="/" className="flex items-center gap-2 md:gap-3">
            <div className="relative w-10 md:w-12 h-10 md:h-12 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-xl ...">
              <div className="absolute inset-0.5 bg-white rounded-lg rotate-6" />
              <span className="relative text-xl md:text-2xl font-bold text-blue-600 rotate-[-18deg]">RDM</span>
            </div>
            <div className="text-lg md:text-xl font-bold text-blue-600">FINANCE SOLUTIONS</div>
          </Link>

          <div className="flex items-center gap-4 md:gap-6">
            <div className="hidden md:flex gap-6 md:gap-8">
              {Object.entries(t.nav).map(([key, value]) => (
                <a key={key} href="#" className="text-gray-600 hover:text-blue-600 whitespace-nowrap">
                  {value}
                </a>
              ))}
            </div>

            <div className="flex items-center gap-4 border-l pl-4 md:pl-6">

                <button
                  onClick={() => setLang('ro')}
                  className={`text-sm font-medium ${lang === 'ro' ? 'text-blue-600' : 'text-gray-500'}`}
              >
                  RO
                  </button>
                  <button
                    onClick={() => setLang('ru')}
                    className={`text-sm font-medium ${lang === 'ru' ? 'text-blue-600' : 'text-gray-500'}`}
                  >
                RU
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-lg mx-auto px-3 py-6 pt-28">
        {step === 1 ? (
          <div className="w-full bg-white rounded-2xl p-6 shadow-sm">
            <div className="space-y-3 mb-6">
              <div className="flex items-center gap-1.5">
                <Globe className="w-4 h-4 text-green-600" />
                <span className="text-xs bg-green-100 text-green-600 px-2.5 py-0.5 rounded-full">
                  {t.badge}
                </span>
              </div>

              <h1 className="text-xl font-bold text-gray-900">{t.title}</h1>
              <p className="text-sm text-gray-600">{t.description}</p>
            </div>

            {error && (
              <div className="mb-5 flex items-center gap-1.5 text-red-600 bg-red-50 p-3 rounded-lg">
                <AlertCircle className="w-4 h-4 flex-shrink-0" />
                <span className="text-xs">{error}</span>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-5">
              {[
            { name: 'idno', type: 'text', label: t.form.idno, placeholder: t.form.idnoPlaceholder },
            { name: 'vehicleDoc', type: 'text', label: t.form.vehicleDoc, placeholder: t.form.vehicleDocPlaceholder },
            { name: 'towingDoc', type: 'text', label: t.form.towingDoc, placeholder: t.form.towingDocPlaceholder, className: 'hidden' },
            { name: 'startDate', type: 'date', label: t.form.startDate }
              ].map(field => (
                <div key={field.name} className={`space-y-1.5 ${field.className || ''}`}>
                  <label className="block text-xs text-gray-700">{field.label}</label>
                  <input
                    type={field.type}
                    value={formData[field.name]}
                    onChange={(e) => handleInput(field.name, e.target.value)}
                    placeholder={field.placeholder}
                    min={field.type === 'date' ? new Date().toISOString().split('T')[0] : undefined}
                    required={field.name !== 'towingDoc'}
                    className={`w-full px-3 py-2 rounded-lg border ${
                      error && field.name === 'idno' ? 'border-red-300 focus:ring-red-200' : 'border-gray-200 focus:ring-green-200'
                    } focus:border-green-500 focus:ring-1 text-sm ${field.className || ''}`}
                  />
                </div>
              ))}

              <div className="space-y-1.5">
                <label className="block text-xs text-gray-700">{t.form.zone}</label>
                <select
                  value={formData.zone}onChange={(e) => handleInput('zone', e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border border-gray-200 focus:border-green-500 focus:ring-1 focus:ring-green-200 text-sm"
                                  >
                                    {Object.entries(t.form.zones).map(([value, label]) => (
                                      <option key={value} value={value}>{label}</option>
                                    ))}
                                  </select>
                                </div>

                                <div className="space-y-1.5">
                                  <label className="block text-xs text-gray-700">{t.form.period}</label>
                                  <select
                                    value={formData.insuranceValidity}
                                    onChange={(e) => handleInput('insuranceValidity', e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border border-gray-200 focus:border-green-500 focus:ring-1 focus:ring-green-200 text-sm"
                                  >
                                    {Object.entries(t.form.periods).map(([value, label]) => (
                                      <option key={value} value={value}>{label}</option>
                                    ))}
                                  </select>
                                </div>

                                <div className="space-y-2.5">
                                  {['gdprConsent', 'termsConsent'].map(consent => (
                                    <label key={consent} className="flex items-center gap-1.5 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        checked={formData[consent]}
                                        onChange={(e) => handleInput(consent, e.target.checked)}
                                        className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                                      />
                                      <span className="text-xs text-gray-600">{t.form[consent]}</span>
                                    </label>
                                  ))}
                                </div>

                                <button
                                  type="submit"
                                  disabled={!isFormValid || isLoading}
                                  className={`w-full bg-green-600 hover:bg-green-700 text-white rounded-lg py-2.5 px-3 
                                    text-sm font-medium transition-colors flex items-center justify-center gap-1.5 
                                    ${(!isFormValid || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                  <span>{isLoading ? t.button.loading : t.button.calculate}</span>
                                  <ArrowRight className="w-4 h-4" />
                                </button>
                              </form>
                            </div>
                          ) : (
                            <InsurancePreviewRCAE 
                              data={insuranceData}
                              lang={lang}
                              onBack={() => setStep(1)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  };

                  export default RCAECalculator;