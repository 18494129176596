import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, Calendar, Clock, Mail, Phone, ArrowRight, ArrowLeft } from 'lucide-react';

const translations = {
  ro: {
    title: "Detalii Asigurare",
    back: "Înapoi",
    details: "Detalii poliță",
    vehicle: "Automobil",
    plateNumber: "Număr înmatriculare",
    company: "Denumirea companiei",
    idnp: "IDNP",
    idno: "IDNO",
    period: "Perioada asigurării",
    benefits: {
      support: "Suport 24/7",
      coverage: "Acoperire completă",
      processing: "Procesare rapidă"
    },
    payment: {
      standardPrice: "Preț standard:",
      selectPeriod: "Selectați perioada de plată:",
      options: {
        oneMonth: "1 lună",
        twoMonths: "2 luni",
        threeMonths: "3 luni"
      },
      perMonth: "lună",
      savings: "Economisiți",
      contacts: {
        phone: "Număr de telefon",
        email: "Adresa de email"
      },
      orderButton: "Comandă asigurarea",
      processing: "Se procesează...",
      errors: {
        orderFailed: "Eroare la procesarea comenzii. Vă rugăm să încercați din nou."
      }
    }
  },
  ru: {
    title: "Детали Страховки",
    back: "Назад",
    details: "Детали полиса",
    vehicle: "Автомобиль",
    plateNumber: "Номерной знак",
    company: "Название компании",
    idnp: "IDNP",
    idno: "IDNO",
    period: "Период страхования",
    benefits: {
      support: "Поддержка 24/7",
      coverage: "Полное покрытие",
      processing: "Быстрая обработка"
    },
    payment: {
      standardPrice: "Стандартная цена:",
      selectPeriod: "Выберите период оплаты:",
      options: {
        oneMonth: "1 месяц",
        twoMonths: "2 месяца",
        threeMonths: "3 месяца"
      },
      perMonth: "месяц",
      savings: "Экономия",
      contacts: {
        phone: "Номер телефона",
        email: "Электронная почта"
      },
      orderButton: "Заказать страховку",
      processing: "Обработка...",
      errors: {
        orderFailed: "Ошибка при обработке заказа. Пожалуйста, попробуйте еще раз."
      }
    }
  }
};

const InsurancePreview = ({ data, lang, onBack }) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [contact, setContact] = useState({ phone: '', email: '' });
  const [isFormValid, setIsFormValid] = useState(false);
  const contactFormRef = useRef(null);
  const t = translations[lang];

  useEffect(() => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.email);
    const isValidPhone = contact.phone.length >= 8;
    setIsFormValid(isValidEmail && isValidPhone);
  }, [contact]);

  useEffect(() => {
    if (selectedOption && contactFormRef.current) {
      const yOffset = -20;
      const element = contactFormRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });

      const firstInput = element.querySelector('input');
      if (firstInput) {
        setTimeout(() => {
          firstInput.focus();
        }, 500);
      }
    }
  }, [selectedOption]);

  const calculatePaymentOptions = (basePrice) => ({
    oneMonth: {
      title: t.payment.options.oneMonth,
      price: basePrice * 0.85,
      discount: 15,
      monthly: basePrice * 0.85,
      savings: Math.round(basePrice * 0.15)
    },
    twoMonths: {
      title: t.payment.options.twoMonths,
      price: basePrice * 0.925,
      discount: 7.5,
      monthly: (basePrice * 0.925) / 2,
      savings: Math.round(basePrice * 0.075)
    },
    threeMonths: {
      title: t.payment.options.threeMonths,
      price: basePrice,
      discount: 0,
      monthly: basePrice / 3,
      savings: 0
    }
  });

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat(lang === 'ro' ? 'ro' : 'ru', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(new Date(dateString));
  };

  const handleSubmitOrder = async () => {
    if (!isFormValid || isSubmitting) return;

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      const endDate = new Date(data?.startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);
      endDate.setDate(endDate.getDate() - 1);

      const orderPayload = {
        customerEmail: contact.email,
        phone: contact.phone,
        orderDetails: {
          ...data,
          paymentPeriod: selectedOption,
          finalPrice: paymentOptions[selectedOption].price,
          expirationDate: endDate.toISOString()
        },
        lang
      };

      const response = await fetch('/api/orders/rca', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderPayload)
      });

      if (!response.ok) {
        throw new Error('Order failed');
      }

      navigate('/order-success', { 
        state: {
          orderData: {
            ...data,
            finalPrice: paymentOptions[selectedOption].price,
            paymentPeriod: selectedOption,
            price: paymentOptions[selectedOption].price, 
            expirationDate: endDate.toISOString(),
            contact: {
              email: contact.email,
              phone: contact.phone
            }
          },
          lang
        }
      });

    } catch (error) {
      console.error('Order error:', error);
      setSubmitError(t.payment.errors.orderFailed);
    } finally {
      setIsSubmitting(false);
    }
  };

  const endDate = new Date(data?.startDate);
  endDate.setFullYear(endDate.getFullYear() + 1);
  endDate.setDate(endDate.getDate() - 1);

  const paymentOptions = calculatePaymentOptions(data?.price || 0);
  const isCompany = data?.idnp?.startsWith('1');

  return (
    <div className="w-full bg-white rounded-xl p-4 shadow-sm">
      <button
        onClick={onBack}
        className="flex items-center gap-1.5 text-gray-600 hover:text-blue-600 mb-4 group"
      >
        <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform" />
        <span className="text-sm">{t.back}</span>
      </button>

      <div className="flex items-center gap-2 mb-4 pb-3 border-b">
        <Shield className="w-5 h-5 text-blue-600" />
        <h2 className="text-lg font-bold text-gray-900">{t.title}</h2>
      </div>

      <div className="bg-gray-50 rounded-lg p-4 mb-4">
        <div className="space-y-3">
          <div className="flex justify-between items-center pb-2 border-b">
            <span className="text-sm font-medium text-gray-900">{t.details}</span>
            <span className="text-sm font-bold text-blue-600">{data?.documentNumber}</span>
          </div>

          <div className="grid gap-2">
            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{t.vehicle}</span>
              <span className="text-right font-medium text-sm">{data?.carModel || "N/A"}</span>
            </div>
            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{t.plateNumber}</span>
              <span className="text-right font-medium text-sm">{data?.plateNumber || "N/A"}</span>
            </div>

            {isCompany && data?.contractorName && (
              <div className="grid grid-cols-2 items-center">
                <span className="text-gray-600 text-sm">{t.company}</span>
                <span className="text-right font-medium text-sm">{data.contractorName}</span>
              </div>
            )}

            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{isCompany ? t.idno : t.idnp}</span>
              <span className="text-right font-medium text-sm">{data?.idnp}</span>
            </div>

            <div className="grid grid-cols-2 items-center">
              <span className="text-gray-600 text-sm">{t.period}</span>
              <span className="text-right font-medium text-sm">
                {formatDate(data?.startDate)} - {formatDate(endDate)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between mb-4">
        <div className="flex items-center gap-1.5">
          <Clock className="w-4 h-4 text-green-600" />
          <span className="text-xs text-gray-600">{t.benefits.support}</span>
        </div>
        <div className="flex items-center gap-1.5">
          <Shield className="w-4 h-4 text-blue-600" />
          <span className="text-xs text-gray-600">{t.benefits.coverage}</span>
        </div>
        <div className="flex items-center gap-1.5">
          <Calendar className="w-4 h-4 text-purple-600" />
          <span className="text-xs text-gray-600">{t.benefits.processing}</span>
        </div>
      </div>

      {submitError && (
        <div className="mb-4 p-3 bg-red-50 text-red-600 text-sm rounded-lg">
          {submitError}
        </div>
      )}

      <div className="bg-blue-50 rounded-lg p-4 mb-4">
        <div className="text-center mb-3">
          <span className="text-sm font-medium text-gray-700">{t.payment.standardPrice} </span>
          <span className="text-xl font-bold text-gray-900">
            {data?.price?.toLocaleString()} MDL
          </span>
        </div>

        <div className="text-center mb-4">
          <span className="text-sm font-medium">{t.payment.selectPeriod}</span>
        </div>

        <div className="space-y-2">
          {Object.entries(paymentOptions).map(([key, option]) => (
            <label
              key={key}
              className={`block cursor-pointer rounded-lg transition-all ${
                selectedOption === key
                  ? 'bg-white border-2 border-blue-500 shadow-sm'
                  : 'bg-white border border-gray-200 hover:border-blue-200'
              }`}
            >
              <input
                type="radio"
                name="payment"
                className="hidden"
                checked={selectedOption === key}
                onChange={() => setSelectedOption(key)}
              />

              <div className="p-3 flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <div className="text-sm font-bold text-gray-900">{option.title}</div>
                  {option.discount > 0 && (
                    <div className="bg-red-500 text-white text-xs px-2 py-0.5 rounded-full">
                      -{option.discount}%
                    </div>
                  )}
                </div>

                <div className="text-right">
                  <div className="text-sm font-bold text-blue-600">
                    {option.price.toLocaleString()} MDL
                  </div>
                  <div className="text-xs text-gray-500">
                    {option.monthly.toLocaleString()} MDL / {t.payment.perMonth}
                  </div>
                  {option.savings > 0 && (
                    <div className="text-xs text-green-600">
                      {t.payment.savings} {option.savings.toLocaleString()} MDL
                    </div>
                  )}
                </div>
              </div>
            </label>
          ))}
        </div>
      </div>

      {selectedOption && (
        <div ref={contactFormRef} className="space-y-3 transition-all duration-300">
          <div className="space-y-2">
            <div className="relative">
              <Phone className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              <input
                type="tel"
                value={contact.phone}
                onChange={(e) => setContact(prev => ({...prev, phone: e.target.value}))}
                className={`w-full pl-9 pr-3 py-2 text-sm rounded-lg border 
                  ${contact.phone ? 
                    (contact.phone.length >= 8 ? 'border-green-200 focus:ring-green-200' : 'border-red-200 focus:ring-red-200') 
                    : 'border-gray-200 focus:ring-blue-200'
                  } 
                  focus:border-blue-500 focus:ring-2`}
                placeholder={t.payment.contacts.phone}
              />
            </div>
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              <input
                type="email"
                value={contact.email}
                onChange={(e) => setContact(prev => ({...prev, email: e.target.value}))}
                className={`w-full pl-9 pr-3 py-2 text-sm rounded-lg border 
                  ${contact.email ? 
                    (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.email) ? 'border-green-200 focus:ring-green-200' : 'border-red-200 focus:ring-red-200') 
                    : 'border-gray-200 focus:ring-blue-200'
                  } 
                  focus:border-blue-500 focus:ring-2`}
                placeholder={t.payment.contacts.email}
                  />
                  </div>
                  </div>
          <button 
                  disabled={!isFormValid || isSubmitting}
                  onClick={handleSubmitOrder}
                  className={`w-full text-white rounded-lg py-3 px-4 text-sm font-medium 
                    transition-all flex items-center justify-center gap-2
                    ${isFormValid && !isSubmitting
                      ? 'bg-blue-600 hover:bg-blue-700 cursor-pointer' 
                      : 'bg-gray-400 cursor-not-allowed opacity-50'
                    }`}
                >
                  {isSubmitting ? (
                    <>
                      <span className="animate-spin">⚪</span>
                      <span>{t.payment.processing}</span>
                    </>
                  ) : (
                    <>
                      <span>{t.payment.orderButton}</span>
                      <ArrowRight className="w-4 h-4" />
                    </>
                  )}
                </button>
              </div>
            )}
          </div>
    );
    };
    export default InsurancePreview;
          